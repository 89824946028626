import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ShowSettingsService } from '@app/shared/services/show-settings.service';

export function showSettings<T>(): CanDeactivateFn<T> {
    return () => {
        const showSettingsService = inject(ShowSettingsService);
        showSettingsService.setBookingDetailsFlag(true);
        return true;
    };
}
